.body-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 20px;
    overflow-y: auto;
}

.centered-box {
    padding: 20px;
    background: #DEF9FF;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 90%;
    z-index: 1;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}

.centered-main-box {
    border: 2px dotted #fff;
    width: 100%;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}

.app__body-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
    margin-top: -20px;
}

.app__body-head-main {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.app__body-head-main h2 {
    color: black;
    font-size: 28px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
}

.app__body-head-main p {
    margin-top: -10px;
    color: black;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}

.app__body-cards {
    display: flex;
    padding-left: 12px;
    padding-right: 12px;
}

.app__body-card {
    box-sizing: border-box;
    border-radius: 20px;
    margin-bottom: 20px;
    width: 100%;
    background-color: #F0FCFF;
    margin-right: 15px;
}

.app__body-first-card {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    border-radius: 10px;
    background: rgba(106, 106, 106, 1);
    color: #fff;
}

.app__body-first-card p {
    color: white;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}

.app__body-first-card-correct-images {
    margin-top: 8px;
}


.app__body-first-card-text-after-correct-images {
    margin-top: 8px;
}

.app__body-first-card-text-after-correct-images p {
    color: black;
    font-size: 10px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}

.app__body-first-card-sample-images-first {
    margin-top: 8px;
}

.app__body-first-card-sample-images-first img {
    width: 90px;
    height: 90px;
    padding: 5px;
}

.app__body-first-card-sample-images-second img {
    width: 90px;
    height: 90px;
    padding: 5px;
}

.app__body-first-card-button {
    padding-bottom: 15px;
}

.app__body-first-card-button a {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background: rgba(106, 106, 106, 1);
    text-decoration: none;
    color: #fff;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 8px;
    border-radius: 10px;
}

.app__body-first-card-button a p {
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}

.app__body-second-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 40px;
    padding-right: 40px;
}

.app__body-second-card p {
    color: black;
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}

.app__body-third-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 40px;
    padding-right: 40px;
}

.app__body-third-card p {
    color: black;
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}

.app__body-third-card-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
}

.app__body-third-card-buttons a {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    color: #fff;
    background: rgba(106, 106, 106, 1);
    border-radius: 10px;
}

.app__body-third-card-buttons a p {
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    padding: 5px;
}



@media screen and (max-width: 1100px) {
    .centered-box {
        padding: 12px;
    }

    .app__body-third-card-buttons {
        padding-top: 8px;
    }

    .app__body-third-card-buttons a p {
        font-size: 10px;
    }
}

@media screen and (max-width: 890px) {
    .app__body-cards {
        flex-direction: column;
    }

    .app__body-card {
        width: 100%;
    }
}




/* For larger screens */
@media screen and (min-width: 1200px) {
    .app__body-card button {
        white-space: nowrap; /* Ensures the button text stays in one line */
    }
}

/* For medium screens */
@media screen and (max-width: 1199px) and (min-width: 768px) {
    .app__body-card button {
        white-space: nowrap; /* Ensures the button text stays in one line */
    }

    .body-container {
        zoom: 0.8;
        -moz-transform: scale(0.8); /* Firefox */
        transform: scale(0.8);
    }
}

/* For small screens */
@media screen and (max-width: 767px) {
    .app__body-card button {
        white-space: normal; /* Allows the button text to wrap to multiple lines */
    }

    .body-container {
        zoom: 0.8;
        -moz-transform: scale(0.8); /* Firefox */
        transform: scale(0.8);
    }
}