
/* Define a fade-in animation */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Apply the fade-in animation to the body-container */
.body-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 1%;
    overflow-y: auto;
    animation: fadeIn 2s ease-in-out;
}

/* Define a scale and color transition for buttons */
.button {
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

/* Apply scale and color change when buttons are hovered */
.button:hover {
    background-color: #08b3e5;
    transform: scale(1.05);
}

/* Add a sliding animation for the centered-box */
.centered-box {
    background: #DEF9FF;
    box-shadow: 0 0 2% rgba(0, 0, 0, 0.1);
    height: 90%;
    width: 90%;
    z-index: 1;
    border-top-right-radius: 2%;
    border-top-left-radius: 2%;
    animation: fadeIn 1s ease-in-out forwards;
    transform: translateY(0%);
}

/* Define keyframes for a slide-in animation */
@keyframes slideIn {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}

/* Apply slide-in animation to cards */
.app__body-cards .app__body-card {
    animation: slideIn 1s ease-in-out;
}