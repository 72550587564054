/* Header.css */

.app__header-logo-image {
    margin-top: 15px;
    z-index: 1;
}

.app__header-header-main {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.app__header-header-secondary {
    display: flex;
    padding: 8px;
    border-radius: 6px;
    background: #6A6A6A;
    z-index: 1;
}

.app__header-header-main a {
    text-decoration: none; /* Remove underline */
    color: white;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    padding: 3px 10px; /* Add padding for better clickability */
    /*border-radius: 1px; /* Add rounded corners */
    /*margin-right: 5px; /* Add some spacing between buttons */
}

.app__header-header-main a:hover {
    background-color: #4E4E4E; /* Change background color on hover */
}

/* Add specific styles for each button */
.app__header-header-home a {
    border-right: 1px solid #fff;
    cursor: pointer;
}

.app__header-header-ocr a {
    border-right: 1px solid #fff;
    cursor: pointer;
}

.app__header-header-team a {
    border-right: 1px solid #fff;
    cursor: pointer;
}

.app__header-header-contributions a {
    border-right: 1px solid #fff;
    cursor: pointer;
}

.app__header-header-contact a {
    border-right: 1px solid #fff;
    cursor: pointer;
}

.app__header-header-about a {
    cursor: pointer;
}

.app__header-logo-image {
    width: 12%; /* Set the desired percentage width */
    height: auto; /* Maintain aspect ratio */
}

